import $router from '@weepower/core/scripts/wee-routes';
import './bootstrap';

// Trigger build 2025 April

// Common and sitewide JS
const common = () => import('./common').then((m) => m.default || m); // Site wide common js
const heroAnimations = () => import('./heroAnimations').then((m) => m.default || m); // Hero load swapping
const sliders = () => import('../components/slider').then((m) => m.default || m); // Flicky Slider (formerly lory)
const animate = () => import('../components/animate').then((m) => m.default || m); // Formerly 'scroller'
const footerNewsletter = () => import('../components/newsletter').then((m) => m.default || m); // Handles subscribing to the newsletter'
const freeform = () => import('../components/freeform').then((m) => m.default || m); // AJAX freeform handling (csrf, honeypot, etc) and modals
const statementAnimations = () => import('../components/statement').then((m) => m.default || m); // Statement block animations
const workPreview = () => import('../components/work-preview').then((m) => m.default || m); // Work preview interactions
const navigation = () => import('../components/navigation').then((m) => m.default || m); // Nav menu interactions

const siteWide = [
    animate,
    common,
    footerNewsletter,
    freeform,
    heroAnimations,
    statementAnimations,
    workPreview,
    sliders,
    navigation,
];

// Page specific JS
const blog = () => import('../components/blog').then((m) => m.default || m);
const emailSig = () => import('../components/email-signature').then((m) => m.default || m); // generate email signature
const jobListings = () => import('../components/contact').then((m) => m.default || m); // Toggling for job listings
const locations = () => import('../components/locations').then((m) => m.default || m); // Office locations w/ Google Maps
const workFilters = () => import('../components/work-filter').then((m) => m.default || m); // Work filter interactions
const accordion = () => import('../components/accordion').then((m) => m.default || m); // Work filter interactions
const blogContent = () => import('../components/blog-content').then((m) => m.default || m); // Blog entry page js

// Simple card currently only on homepage
const simpleCard = () => import('../components/flex-22/simple-card').then((m) => m.default || m); // Simple card interactions

// Healthcare LP
const healthcareLp = () => import('../components/healthcare-lp').then((m) => m.default || m);

// Prospects LP
const prospectsLp = () => import('../components/prospects-lp').then((m) => m.default || m);

// Prospects v2 LP
const prospectsV2 = () => import('../components/_prospect-blocks').then((m) => m.default || m);
const prospectsChildren = () => import('../components/prospects-childrens').then((m) => m.default || m);

// Telehealth specific JS
const telehealthFade = () => import('../components/telehealth-page/fade-scroll').then((m) => m.default || m); // Some scroll animations for TH
const telehealthHero = () => import('../components/telehealth-page/hero-block').then((m) => m.default || m); // Hero images for TH
const telehealthNav = () => import('../components/telehealth-page/navigation').then((m) => m.default || m); // TH Nav open/close menu mobile
const telehealthSlider = () => import('../components/telehealth-page/slideshow').then((m) => m.default || m); // Slideshow animations for TH

const thScripts = [
    telehealthFade,
    telehealthHero,
    telehealthNav,
];

// TODO: (lyle) removed .pjax from this init (review if pjax is used on site)
$router.map([
    {
        path: '/',
        handler: [
            ...siteWide,
            simpleCard,
        ],
    },
    {
        path: '/about',
        handler: [
            ...siteWide,
        ],
    },
    {
        path: '/blog',
        handler: [
            ...siteWide,
            blog,
        ],
    },
    {
        path: '/blog/:page',
        handler: [
            ...siteWide,
            blogContent,
        ],
    },
    {
        path: '/careers',
        handler: [
            ...siteWide,
            jobListings,
        ],
    },
    {
        path: '/careers/:page',
        handler: [
            ...siteWide,
        ],
    },
    {
        path: '/contact',
        handler: [
            ...siteWide,
            locations,
            accordion,
        ],
    },
    {
        path: '/contact/:page',
        handler: [
            ...siteWide,
            locations,
        ],
    },
    {
        path: '/telehealthebook',
        handler: [
            ...siteWide,
            ...thScripts,
            telehealthSlider,
        ],
    },
    {
        path: '/telehealthroadmap',
        handler: [
            ...siteWide,
            ...thScripts,
        ],
    },
    {
        path: '/work',
        handler: [
            ...siteWide,
            workFilters,
        ],
    },
    {
        path: '/work/:page', // TODO: Any extra stuff needed on work pages?
        handler: [
            ...siteWide,
        ],
    },
    {
        path: '/email-sig',
        handler: [
            ...siteWide,
            emailSig,
        ],
    },
    {
        path: '/healthcare',
        handler: [
            ...siteWide,
            healthcareLp,
        ],
    },
    {
        path: '/trustcare-new',
        handler: [
            ...siteWide,
            prospectsLp,
        ],
    },
]).notFound({
    handler: [
        ...siteWide,
        prospectsLp,
        prospectsV2,
        prospectsChildren,
    ],
}).run();
